<template>
    <section class="section" id="getintouch">
		<div class="column is-full has-text-centered">
			<p class="is-size-1 has-text-weight-bold">Hör av dig</p>
			<p>Hör av dig så utforskar vi den bästa lösningen för ditt team</p>
		</div>
		<div class="column is-full has-text-centered">
			<a class="button" href="mailto:info@teamladan.se">
				<span>Mejla oss &nbsp;</span>
				<span class="icon-text">
					<font-awesome-icon icon="fas fa-solid fa-envelope" />
				</span>
			</a>
			<!--<router-link class="button" to="/contact">
				<span>Kontakt &nbsp;</span>
				<span class="icon-text">
					<font-awesome-icon icon="fa-solid fa-pen-to-square" />
				</span>
			</router-link>-->
			<a class="button" href="https://www.linkedin.com/company/teaml%C3%A5dan/about/">
				<span>LinkedIn &nbsp;</span>
				<span class="icon-text">
					<font-awesome-icon icon="fab fa-brands fa-linkedin" />
				</span>
			</a>
		</div>
	</section>
</template>

<script>
export default {
  name: 'HorAvDig',
  props: {
    msg: String
  }
}
</script>

<style>

</style>