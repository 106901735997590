<template>
    <footer class="footer">
	<div class="content has-text-centered">
		<p>
			<strong>Teamlådan</strong> by <a href="https://teamladan.se">Teamlådan AB</a>.
			<br>
			© 2022 Stumbly AB. All Rights Reserved.
		</p>
	</div>
</footer>
</template>

<script>
export default {
  name: 'FooterBar'
}
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";
</style>