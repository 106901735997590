<template>
<nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <a class="navbar-item" href="https://teamladan.se"> <img src="../assets/loggavit.png" width="112" height="28">
        </a>
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" @click="toggleNavbar">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': isActive }">
        <div class="navbar-start"></div>
        
        <div class="navbar-end">
            <div class="navbar-item">
                <router-link class="navbar-item" to="/" :class="{ 'is-active': $route.path === '/' }">Home</router-link>
                <router-link class="navbar-item" to="/about" :class="{ 'is-active': $route.path === '/about' }">About</router-link>
                <!--<router-link class="navbar-item" to="/contact" :class="{ 'is-active': $route.path === '/contact' }">Contact</router-link>-->
            </div>
        </div>
    </div>
</nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggleNavbar() {
      this.isActive = !this.isActive;
    },
  },
};
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";
.navbar {
    background-color: #EB9C81;
}

.navbar-menu {
    background-color: #EB9C81 !important;
}

.navbar-brand {
    background-color: #EB9C81;
}

.navbar-item {
    background-color: #EB9C81;
    color:white !important;
}

.navbar-item:hover
{
	background-color:#EB9C81 !important;
	color: black !important;
}

.navbar-item.is-active {
  background-color: transparent !important;
  color:black !important;
}

.active {
    background-color: #EB9C81;
    color:black;
}
</style>