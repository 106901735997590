<template>
  <section class="section is-medium">
    <div class="column is-6 is-offset-3 has-text-weight-bold has-text-centered">
      <p class="white-text is-size-1">Kontakta Oss</p>
      <p class="white-text is-size-5">
        Om du har några frågor eller funderingar om Teamlådan eller våra tjänster, tveka inte att kontakta oss. Vi ser fram emot att hjälpa dig. Vänligen fyll i formuläret nedan eller mejla oss på <a href="mailto:info@teamladan.se">info@teamladan.se</a> så återkommer vi så snart som möjligt.
      </p>
    </div>
    <div class="container">
      <form ref="form" @submit.prevent="submitForm" class="form">
        <label class="form-label">
          Namn:
          <input class="form-input" type="text" v-model="formData.name" />
        </label>
        <br />
        <label class="form-label">
          Email:
          <input class="form-input" type="email" v-model="formData.email" />
        </label>
        <br />
        <label class="form-label">
          Meddelande:
          <textarea class="form-textarea" v-model="formData.message"></textarea>
        </label>
        <br />
        <button class="form-button" type="submit">Skicka</button>
      </form>
    </div>
    <div class="column">
					<figure class="image">
						<img class="rotate20" alt="box" src="../assets/box3brun.png">
					</figure>
				</div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: ""
      }
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.checkValidity()) {
        try {
          const response = await axios.post('http://localhost:8080/contacts', this.formData);
          // show success message
          console.log(response);
        } catch (error) {
          // show error message
          console.error(error);
        }
      } else {
        // show error message
      }
    }
  }
};
</script>

<style>
@media only screen and (min-width: 769px) {
  section:nth-child(2) {
    background-color: #EB9C81;
    min-height: 100vh;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  background-color: #f2f2f2;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 60%;
}

.form-label {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 0.5rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.form-textarea {
  min-height: 5rem;
}

.form-button {
  background-color: #4caf50;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
}

.form-button:hover {
  background-color: #3e8e41;
}
</style>