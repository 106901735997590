<template>
	<section class="section is-medium">
		<div class="column is-offset-1">
			<div class="columns is-multiline">
				<div class="column is-full is-half-desktop has-text-centered has-text-left-desktop">
					<P class="white-text no-word-break title is-1 title is-size-2-mobile title is-size-2-tablet">
						Teamlådan <br>
					</P>
					<P class="white-text no-word-break subtitle is-1 title is-size-2-mobile title is-size-2-tablet">
						Ett roligt och effektivt sätt för att öka arbetslagets sammanhållning
					</P>
					<p class="white-text has-text-weight-bold content is-medium">
						På Teamlådan är vårt mål att erbjuda en enkel och rolig lösning för företag att genomföra teambuilding på regelbunden basis. Vi tror på att kontinuerlig teambuilding är nyckeln till en stark och sammanhållen arbetsgrupp och ökad produktivitet. Vi strävar efter att skapa unika och anpassade lösningar för varje kund, med en stark fokus på kvalitet och kundnöjdhet.
					</p>
					<br>
					<a class="pinkbutton white-text button is-responsive is-rounded" v-on:click="getstarted()">&nbsp;&nbsp;&nbsp; Kom Igång! &nbsp;&nbsp;&nbsp;</a>
				</div>

				<div class="column is-full is-half-desktop">
					<figure class="image">
						<img class="rotate20" alt="box" src="../assets/box3brun.png">
					</figure>
				</div>
			</div>
		</div>
	</section>
	<section class="section" id="getstarted">
		<div class="column is-12 has-text-centered">
			<div class="columns">
				<div class="column has-text-weight-bold">
					<div class="columns is-multiline is-gapless is-centered">
						<div class="column is-12">
							<p class="title is-3">1. Specialanpassa och beställ</p>
						</div>
						<div class="column is-10">
							<p>Anpassa din teamlåda efter storleken på just ditt team och era preferenser. Vi sätter ihop och skräddarsyr lådan enligt era önskemål.</p>
						</div>
					</div>
				</div>
				<div class="column has-text-weight-bold">
					<div class="columns is-multiline is-gapless is-centered">
						<div class="column is-12">
							<p class="title is-3">2. Öppna och utvecklas</p>
						</div>
						<div class="column is-10">
							<p>Lådan levereras till er dörr varje kvartal när det är dags för er utvecklingsfika. Samla ditt team, öppna lådan och utvecklas tillsammans!</p>
						</div>
					</div>
				</div>

				<div class="column has-text-weight-bold">
					<div class="columns is-multiline is-gapless is-centered">
						<div class="column is-12">
							<p class="title is-3">3. Följ upp och se resultat</p>
						</div>
						<div class="column is-10">
							<p>Deltagare uppmuntras svara på en handfull anonyma frågor.
								Följ teamets utveckling och anpassa nästa teamlåda efter identifierade utvecklingsområden.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<br><br><br>
		<div class="column is-10 is-offset-1 has-text-centered has-text-weight-bold">
			<p class="is-size-3">Ta steget och investera i kontinuerlig teambuilding för din organisation.</p>
			<p>Tillsammans kan vi skapa en stark och sammanhållen arbetsgrupp, öka produktiviteten och minska stressen. </p>
			<p>Kontakta oss idag för att börja planera din teambuildingstrategi!</p>
			<br>
			<button class="pinkbutton white-text button is-responsive is-rounded" v-on:click="getintouch()">&nbsp; &nbsp; &nbsp; Kontakta Oss &nbsp; &nbsp; &nbsp;</button>
		</div>
	</section>
	<section class="section company-pink">
		<div class="column is-4 is-offset-4 has-text-weight-bold">
			<p class="white-text is-size-3">Om Teamlådan</p>
			<p class="white-text">Teamlådan är det effektiva och smidiga sättet att jobba med teambuilding. Lådan innehåller en spännande
				blandning fika och beprövade övningar. Vi erbjuder ett enkelt och roligt sätt att
				kontinuerligt jobba med ert team för att främja sammanhållning och välmående.</p>
		</div>
	</section>
</template>

<script>
export default {
	name: 'HomePage',
	props: {
		msg: String
	},
	methods: {
		getstarted() {
			document.getElementById("getstarted").scrollIntoView({ behavior: 'smooth' }, true);
		},
		getintouch() {
			document.getElementById("getintouch").scrollIntoView({ behavior: 'smooth' }, true);
		}
	}
}
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";

.button:hover {
	border-color: black;
	color: black;
}

.pinkbutton {
	background-color: #EB9C81;
	border-color: #EB9C81;
}

section:nth-child(2) {
	background-image: url('../assets/hands.jpg');
}

.white-text {
	color: white;
}

.grey-text {
	color: #ffcc77;
}

.pink-text {
	color: #EB9C81;
}

.company-pink {
	background-color: #EB9C81;
}

.no-word-break {
	word-break: keep-all;
}

.image {
	width: 75%;
	height: 75%;
	display: block;
	margin-left: auto;
	margin-right: auto;

}

@media only screen and (min-width: 1023px) {
	section:nth-child(2) {
		min-height: 100vh;
	}

	.image {
		width: 15%;
		height: 15%;
		position: absolute;
		bottom: 2.5rem;
		right: 2rem;
	}

	.rotate20 {
		-webkit-transform: rotate(20deg);
		-moz-transform: rotate(20deg);
		-o-transform: rotate(20deg);
		-ms-transform: rotate(20deg);
		transform: rotate(20deg);
	}
}
</style>