<template>
  <section class="section is-medium">
    <div class="column is-6 is-offset-3 has-text-weight-bold">
      <p class="white-text is-size-1">Om Teamlådan</p>
      <p class="white-text is-size-5">
        Teamlådan är ett företag grundat av tre vänner 2020 med målet att skapa mer sammanhållning på arbetsplatser och förbättra relationerna mellan kollegor. Vi tror att en stark lagkänsla är grunden för ett välmående och framgångsrikt företag.
        Därför har vi skapat Teamlådan - en unik prenumerationslösning som innehåller både fika och teambuildingövningar. Med vår produkt kan du enkelt och smidigt arrangera roliga aktiviteter för ditt team på en regelbunden basis.
        Vi hoppas att du vill testa Teamlådan och uppleva skillnaden själv. Tveka inte att höra av dig till oss om du har några frågor eller funderingar. Vi ser fram emot att hjälpa dig att skapa en starkare teamkänsla på din arbetsplats!
      </p>
    </div>
    <div class="column">
					<figure class="image">
						<img class="rotate20" alt="box" src="../assets/box3brun.png">
					</figure>
				</div>
  </section>
  <section class="section is-medium oppenlada">
    <div class="column is-half-desktop is-offset-6-desktop  has-text-weight-bold padl">
      <p class="is-size-2">Varför Teambuilding?</p>
      <p>
        Kontinuerlig teambuilding är viktigt för ett företag eftersom det bidrar till att skapa en stark och sammanhållen arbetsgrupp.
        Detta kan i sin tur leda till ökad produktivitet, effektivitet och kreativitet.

        När teammedlemmarna känner varandra väl och litar på varandra är det lättare att kommunicera och samarbeta.
        Det minskar också risken för missförstånd och oenigheter.
        Detta kan leda till minskad stress och ökad jobbtillfredsställelse för anställda.

        Kontinuerlig teambuilding kan också bidra till att utveckla nya idéer och lösningar på problem.
        När teammedlemmarna träffas regelbundet, kan de diskutera problem och utmaningar de står inför och arbeta tillsammans för att hitta lösningar.

        Dessutom, genom kontinuerlig teambuilding kan man skapa en kulturell och mentalitetsförändring i företaget,
        där man får en mer positiv och engagerad personal.

        Sammantaget, kontinuerlig teambuilding är viktigt för ett företag eftersom det bidrar till att skapa en stark och sammanhållen arbetsgrupp,
        ökar produktiviteten, effektiviteten och kreativiteten,
        minskar stress och ökar jobbtillfredsställelsen samt bidrar till en positiv kulturell och mentalitetsförändring i företaget.
      </p>
    </div>
  </section>
  <section class="section is-medium company-pink stangdlada">
    <div class="column is-half-desktop has-text-weight-bold padr">
      <p class="white-text is-size-2">Vår vision</p>
      <p class="white-text">Vår vision är att göra teambuilding till en regelbunden och rolig aktivitet på arbetsplatser överallt. Vi tror att en stark teamkänsla är en viktig faktor för att skapa ett hälsosamt och framgångsrikt företagsklimat, och vi vill göra det möjligt för företag att enkelt integrera teambuilding som en del av sin arbetsrutin. Genom att göra det mer tillgängligt och prisvärt, hoppas vi kunna hjälpa fler företag att skapa en kultur som främjar samarbetet och stärker arbetsgruppen. Vi strävar efter att göra teambuilding till en självklarhet på arbetsplatserna, och vi är övertygade om att vår lösning kan göra en positiv skillnad för företag i alla storlekar och branscher.</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutPage',
  props: {
    msg: String
  }
}
</script>

<style>
.company-pink {
  background-color: #EB9C81;
}

.oppenlada {
  background-image: url('../assets/teamladan_oppen.jpg');
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-position: left top;
}

.stangdlada {
  background-image: url('../assets/teamladan_stangd.jpg');
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-position: right top;
}

.padl {
  padding-left: 5%;
}

.padr {
  padding-right: 5%;
}

@media only screen and (max-width: 1023px) {
  .oppenlada {
  background-image: none;
  background-color: white;
}

.stangdlada {
  background-image: none;
  background-color: #EB9C81;
}
}
</style>