<template>
  <NavBar />
  <router-view></router-view>
  <HorAvDig />
  <FooterBar />
</template>

<script>
import NavBar from './components/NavBar.vue'
import HorAvDig from './components/HorAvDig.vue'
import FooterBar from './components/FooterBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    HorAvDig,
    FooterBar
  }
}
</script>

<style>
#app {
  font-family: 'Poppins', sans-serif !important;
}
</style>
