import { createApp } from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from 'vue-router'  
import axios from 'axios'

import HomePage from './components/HomePage.vue'
import AboutPage from './components/AboutPage.vue'
import ContactPage from './components/ContactPage.vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'


axios.defaults.baseURL = 'http://teamldan.se'
//axios.defaults.headers.common['Authorization'] = 'Bearer YOUR_TOKEN'


/* add icons to the library */
library.add(faEnvelope, faPenToSquare, faLinkedin)

const routes = [
    { path: '/', component: HomePage },
    { path: '/about', component: AboutPage },
    { path: '/contact', component: ContactPage }
  ];
  
const router = createRouter({
    history: createWebHistory(),
    routes
});

createApp(App)
  .use(router)
  .provide({ $axios: axios })
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
